<script setup lang="ts">
import type { FunctionalComponent } from "vue";
import type { RouteLocationRaw } from "vue-router";
import { VCard } from "vuetify/components/VCard";
import { VProgressLinear } from "vuetify/components/VProgressLinear";

defineEmits<{
  drop: [unknown];
  dragover: [unknown];
  click: [unknown];
}>();

interface Props {
  to?: RouteLocationRaw;
  color?: string;
  height?: string | number;
  width?: string | number;
  minWidth?: string | number;
  maxWidth?: string | number;
  minHeight?: string | number;
  maxHeight?: string | number;
  loading?: boolean;
  disabled?: boolean;
  appendIcon?: string | (new () => any) | FunctionalComponent; // previously `append`
  variant?: "flat" | "elevated" | "tonal" | "text" | "plain"; // previously `outlined` but as an option now
  border?: boolean;
  flat?: boolean;
  rounded?: boolean | number | string;
  onClick?: (event: MouseEvent) => void;
  onDrop?: (event: DragEvent) => void;
  onDragover?: (event: DragEvent) => void;
  theme?: "light" | "dark";
}

const props = withDefaults(defineProps<Props>(), {
  onClick: undefined,
  onDrop: undefined,
  onDragover: undefined,
  to: undefined,
  color: undefined,
  height: undefined,
  width: undefined,
  minWidth: undefined,
  maxWidth: undefined,
  minHeight: undefined,
  maxHeight: undefined,
  loading: false,
  disabled: undefined,
  appendIcon: undefined,
  variant: "flat",
  border: true,
  flat: undefined,
  rounded: undefined,
  theme: undefined,
});
</script>

<template>
  <VCard v-bind="props">
    <template #loader="{ isActive }">
      <VProgressLinear
        :active="isActive"
        color="primary"
        height="2"
        indeterminate
      />
    </template>

    <template
      v-if="$slots['default']"
      #default
    >
      <slot name="default" />
    </template>
    <template
      v-if="$slots['title']"
      #title
    >
      <div class="whitespace-normal">
        <slot name="title" />
      </div>
    </template>
    <template
      v-if="$slots['subtitle']"
      #subtitle
    >
      <div class="whitespace-normal">
        <slot name="subtitle" />
      </div>
    </template>
    <template
      v-if="$slots['text']"
      #text
    >
      <slot name="text" />
    </template>
  </VCard>
</template>
